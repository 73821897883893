import { WebCartItem } from '@/models/Cart';
import { ProductSearchResultProduct } from '@/models/Product';
import { VariantModel } from '@/models/Variant';
import { toIncVat } from '@/utils/NumberUtils';

export default class TrackingService {

    public trackLogin() {

        // ga4 tracking
        if (typeof (window as any).gtag === 'function')
        {
            (window as any).gtag('event', 'login', {});
        }
    }

    public trackAddToCart(item: WebCartItem) {

        if (!item)
            return;

        // facebook pixel tracking
        if (typeof (window as any).fbq === 'function')
        {
            (window as any).fbq('track', 'AddToCart', {
                'content_name': item.name,
                'content_category': '',
                'content_ids': [item.productid],
                'content_type': 'product',
                'value': toIncVat(item.unitprice.price, item.unitprice.vatrate).toFixed(2),
                'currency': item.unitprice.currency
            });
        }

        // ga4 tracking
        if (typeof (window as any).gtag === 'function')
        {
            const linepriceincvat = item.unitprice.price * item.quantity * (1 + (item.unitprice.vatrate / 100));
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
            const unitpricediscountamountincvat = item.unitprice.discountamount * (1 + (item.unitprice.vatrate / 100));

            (window as any).gtag('event', 'add_to_cart', {
                currency: item.unitprice.currency,
                items: [{
                  'item_id': item.productid,
                  'item_name': item.name,
                  coupon: '',
                  discount: unitpricediscountamountincvat.toFixed(2),
                  affiliation: '',
                  'item_brand': '',
                  'item_category': '',
                  'item_variant': '',
                  price: (unitpriceincvat + unitpricediscountamountincvat).toFixed(2),
                  currency: item.unitprice.currency,
                  quantity: item.quantity
                }],
                value: linepriceincvat.toFixed(2)
            });
        }
    }

    public trackAddToWishlist(product: ProductSearchResultProduct, variant: VariantModel, quantity: number) {

        const item: any = variant ? variant : product;

        if (!item)
            return;

        // ga4 tracking
        if (typeof (window as any).gtag === 'function')
        {
            (window as any).gtag('event', 'add_to_wishlist', {
                currency: item.price.currency,
                items: [{
                  'item_id': item.id,
                  'item_name': item.name,
                  coupon: '',
                  discount: item.price.discountamountincvatasnumeric,
                  affiliation: '',
                  'item_brand': '',
                  'item_category': '',
                  'item_variant': '',
                  price: item.price.priceincvatasnumeric,
                  currency: item.price.currency,
                  quantity: quantity
                }],
                value: item.price.priceincvatasnumeric
            });
        }
    }

    public trackViewItem(item: ProductSearchResultProduct) {

        // ga4 tracking
        if (typeof (window as any).gtag === 'function')
        {
            (window as any).gtag('event', 'view_item', {
                currency: item.price.currency,
                items: [{
                    'item_id': item.id,
                    'item_name': item.name,
                    coupon: '',
                    discount: item.price.discountamountincvatasnumeric,
                    affiliation: '',
                    'item_brand': '',
                    'item_category': '',
                    'item_variant': '',
                    price: item.price.priceincvatasnumeric,
                    currency: item.price.currency,
                    quantity: item.colli
                  }],
                value: item.price.priceincvatasnumeric
            });
        }
    }

    public trackViewCart(items: WebCartItem[]) {

        if (!items)
            return;

        // ga4 tracking
        if (typeof (window as any).gtag === 'function')
        {
            const currency = items[0].unitprice.currency;
            let value: number = 0;
            items.forEach(item => {
                value += toIncVat(item.lineprice.price, item.lineprice.vatrate);
            });

            (window as any).gtag('event', 'view_cart', {
                currency: currency,
                items: items.map(function (item) {
                    return {
                        'item_id': item.productid,
                        'item_name': item.name,
                        coupon: '',
                        discount: toIncVat(item.unitprice.discountamount, item.unitprice.vatrate),
                        affiliation: '',
                        'item_brand': '',
                        'item_category': '',
                        'item_variant': '',
                        price: toIncVat(item.unitprice.price, item.unitprice.vatrate),
                        currency: item.unitprice.currency,
                        quantity: item.quantity
                    }
                }),
                value: value
            });
        }
    }

    public trackBeginCheckout(items: WebCartItem[], coupon: string = '') {

        if (!items)
            return;

        // ga4 tracking
        if (typeof (window as any).gtag === 'function')
        {
            const currency = items[0].unitprice.currency;
            let value: number = 0;
            items.forEach(item => {
                value += toIncVat(item.lineprice.price, item.lineprice.vatrate);
            });

            (window as any).gtag('event', 'begin_checkout', {
                coupon: coupon,
                currency: currency,
                items: items.map(function (item) {
                    return {
                        'item_id': item.productid,
                        'item_name': item.name,
                        coupon: '',
                        discount: toIncVat(item.unitprice.discountamount, item.unitprice.vatrate),
                        affiliation: '',
                        'item_brand': '',
                        'item_category': '',
                        'item_variant': '',
                        price: toIncVat(item.unitprice.price, item.unitprice.vatrate),
                        currency: item.unitprice.currency,
                        quantity: item.quantity
                    }
                }),
                value: value
            });
        }
    }
}
